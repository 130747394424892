import React from 'react';

import { divide } from '@bae/utils';

import useMistStore from '@/app/shared-pages/Scenario/components/digital-twin/MistSettings/store';
import { LicenseConfiguration } from '@/app/shared-pages/Scenario/components/digital-twin/MistSettings/types.ts';
import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from '@/components/ui/accordion.tsx';
import { Checkbox } from '@/components/ui/checkbox.tsx';
import { Label } from '@/components/ui/label.tsx';
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select.tsx';

const OptionalLicenses = () => {
    const optionalLicenses = useMistStore(
        (state) => state.data.optionalLicenses,
    );
    const updateOptionalLicenses = useMistStore(
        (state) => state.updateOptionalLicense,
    );

    const { term, total_number, ...licenses } = optionalLicenses.aps;

    const termInYears = divide(term, 12).toString();

    return (
        <Accordion
            defaultValue='accessPoint'
            type='single'
            collapsible
            className='w-full'>
            <AccordionItem value='accessPoint'>
                <AccordionTrigger className='cursor-pointer rounded-lg border-none bg-newDesign-background p-2'>
                    <div className='flex w-full items-center justify-between pr-10'>
                        <p>Access Point</p>

                        <div className='flex items-center gap-2'>
                            <Label>License Term</Label>
                            <Select
                                value={termInYears}
                                onValueChange={(val) =>
                                    updateOptionalLicenses(
                                        'term',
                                        parseInt(val) * 12,
                                    )
                                }
                                defaultValue='1'>
                                <SelectTrigger className='border-tag h-8 w-[100px]'>
                                    <SelectValue />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectGroup>
                                        {['1', '3', '5'].map((term) => (
                                            <SelectItem key={term} value={term}>
                                                {term + ' Year'}
                                            </SelectItem>
                                        ))}
                                    </SelectGroup>
                                </SelectContent>
                            </Select>
                        </div>
                    </div>
                </AccordionTrigger>

                <AccordionContent>
                    <div className='flex flex-col gap-4 p-4'>
                        {Object.entries(licenses).map(
                            ([key, licenseConfig]) => {
                                const config =
                                    licenseConfig as LicenseConfiguration;

                                return (
                                    <div
                                        className='flex items-center space-x-2'
                                        key={key}>
                                        <Checkbox
                                            onCheckedChange={(checked) => {
                                                if (checked) {
                                                    updateOptionalLicenses(
                                                        'total_number',
                                                        total_number + 1,
                                                    );
                                                } else {
                                                    updateOptionalLicenses(
                                                        'total_number',
                                                        total_number - 1,
                                                    );
                                                }

                                                updateOptionalLicenses(key, {
                                                    ...config,
                                                    status: !!checked,
                                                });
                                            }}
                                            checked={config.status}
                                            disabled={config.always_true}
                                            id={key}
                                        />
                                        <Label htmlFor={key}>
                                            {`${config.nameTag} (${config.skuBase})`}
                                        </Label>
                                    </div>
                                );
                            },
                        )}
                    </div>
                </AccordionContent>
            </AccordionItem>
        </Accordion>
    );
};

export default OptionalLicenses;
