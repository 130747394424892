import React from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';

import { RouteMap } from '@bae/routes';
import { MICRODATA_TABS } from '@bae/utils';

import { getEnabledAppFeatures } from '@/app-config/featureConfig.ts';
import NavBar from '@/layout/NavBar.tsx';
import { cn } from '@/lib/utils.ts';

import { buildUrl } from '../utils.ts';

const ScenarioNavBar = ({
    checkIfAllowedRoute,
}: {
    checkIfAllowedRoute: (route: string) => boolean;
}) => {
    const { url } = useRouteMatch();

    const location = useLocation();
    const search = new URLSearchParams(location.search);

    return (
        <div className='w-full overflow-auto'>
            <NavBar>
                {getEnabledAppFeatures().enabledScenarioRoutes.map((tab) => {
                    if (tab.path === RouteMap.modelDesigner.path) return;

                    if (
                        tab.path === RouteMap.microdata.path &&
                        checkIfAllowedRoute(tab.path)
                    ) {
                        return (
                            <NavBar.DropdownTab key={tab.path} text='Microdata'>
                                {MICRODATA_TABS.map(({ text, to }) => (
                                    <NavBar.DropDownTabItem
                                        key={to}
                                        text={text}
                                        to={buildUrl(`${url}/${to}`, search)}
                                    />
                                ))}
                            </NavBar.DropdownTab>
                        );
                    }

                    return (
                        checkIfAllowedRoute(tab.path) && (
                            <NavBar.Tab
                                className={cn({
                                    'bg-newDesign-primary text-white hover:bg-newDesign-primary-hover':
                                        tab.path === 'settings',
                                })}
                                key={tab.path}
                                to={buildUrl(`${url}/${tab.path}`, search)}
                                text={tab.label}
                                exact
                            />
                        )
                    );
                })}
            </NavBar>
        </div>
    );
};

export default ScenarioNavBar;
