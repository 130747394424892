import { Table as TableDef } from '@tanstack/react-table';
import React from 'react';

import { Button } from '@/components/ui/button.tsx';
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select.tsx';

import { getShowingResultsPaginationText } from '../utils.ts';

const TablePagination = <T,>({ table }: { table: TableDef<T> }) => {
    return (
        <div className='flex items-center justify-end space-x-2 py-4'>
            <div className='flex items-center space-x-2'>
                <p className='text-sm'>
                    {getShowingResultsPaginationText<T>(table)}
                </p>

                <Select
                    value={table.getState().pagination.pageSize?.toString()}
                    onValueChange={(pageSize) => {
                        table.setPageSize(Number(pageSize));
                    }}>
                    <SelectTrigger className='border-tag h-10 w-[100px]'>
                        <SelectValue defaultValue='5' />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectGroup>
                            <SelectLabel>Page Size</SelectLabel>
                            {['5', '10', '20', '30', '40', '50'].map(
                                (pageSize) => (
                                    <SelectItem key={pageSize} value={pageSize}>
                                        {'Show ' + pageSize}
                                    </SelectItem>
                                ),
                            )}
                        </SelectGroup>
                    </SelectContent>
                </Select>

                <Button
                    variant='outline'
                    size='sm'
                    onClick={() => table.previousPage()}
                    disabled={!table.getCanPreviousPage()}>
                    Previous
                </Button>
                <Button
                    variant='outline'
                    size='sm'
                    onClick={() => table.nextPage()}
                    disabled={!table.getCanNextPage()}>
                    Next
                </Button>
            </div>
        </div>
    );
};

export default TablePagination;
