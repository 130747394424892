import React from 'react';
import CurrencyInput, { CurrencyInputProps } from 'react-currency-input-field';

import { cn } from '@/lib/utils.ts';

const NumberField = ({ className, ...props }: CurrencyInputProps) => {
    return (
        <CurrencyInput
            intlConfig={{ locale: 'en-US' }}
            defaultValue={0}
            allowDecimals={false}
            allowNegativeValue={false}
            className={cn(
                'h-8 w-full rounded-md border px-3 py-2 focus-visible:outline-none',
                'focus-visible:ring-1 focus-visible:ring-neutral-950 focus-visible:ring-offset-1',
                className,
            )}
            {...props}
        />
    );
};

export default NumberField;
