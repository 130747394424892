import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import { Usecases } from '@bae/data-interface';
import ConnectionMap from '@bae/modules/connection-map';
import Expenses from '@bae/modules/expenses-breakdown';
import Financials from '@bae/modules/financials';
import Inventory from '@bae/modules/inventory';
import Kpis from '@bae/modules/kpis';
import LabelsManagement from '@bae/modules/labels';
import Microdata from '@bae/modules/microdata';
import ModelDesigner from '@bae/modules/model-designer';
import Services from '@bae/modules/services';
import Topology from '@bae/modules/topology';
import { RouteMap } from '@bae/routes';
import { getModel } from '@bae/store';

import { getEnabledAppFeatures } from '@/app-config/featureConfig.ts';
import { getTemplate } from '@/libs/modules/templates/utils.tsx';
import { useAppSelector } from '@/store';

import MistSettings from './components/digital-twin/MistSettings/MistSettings.tsx';
import { getIsAllowedRoute } from './utils.ts';

const ScenarioRouter = ({
    isShared,
    template,
    sharedTabs,
}: {
    sharedTabs: string[];
    isShared: boolean;
    template?: Usecases;
}) => {
    const { url, path } = useRouteMatch();

    const model = useAppSelector(getModel);

    const isAllowedRoute = (route: string) => {
        return getIsAllowedRoute(route, sharedTabs, isShared);
    };

    const { kpiSections, financialSections, inventorySections } =
        getEnabledAppFeatures();

    return (
        <Switch>
            {isAllowedRoute(RouteMap.settings.path) && (
                <Route
                    exact
                    path={`${path}/${RouteMap.settings.path}`}
                    component={MistSettings}
                />
            )}

            {isAllowedRoute(RouteMap.kpis.path) && (
                <Route
                    exact
                    path={`${path}/${RouteMap.kpis.path}`}
                    component={() => (
                        <Kpis allowedSections={kpiSections} model={model} />
                    )}
                />
            )}

            {isAllowedRoute(RouteMap.services.path) && (
                <Route
                    path={`${path}/${RouteMap.services.path}`}
                    exact
                    component={() => <Services model={model} />}
                />
            )}

            {isAllowedRoute(RouteMap.expenses.path) && (
                <Route
                    path={`${path}/${RouteMap.expenses.path}`}
                    exact
                    component={() => <Expenses modelId={model.id} />}
                />
            )}

            {isAllowedRoute(RouteMap.financials.path) && (
                <Route
                    path={`${path}/${RouteMap.financials.path}`}
                    exact
                    component={() => (
                        <Financials
                            allowedSections={financialSections}
                            modelId={model.id}
                        />
                    )}
                />
            )}

            {isAllowedRoute(RouteMap.topology.path) && (
                <Route
                    path={`${path}/${RouteMap.topology.path}`}
                    exact
                    component={() => <Topology />}
                />
            )}

            {isAllowedRoute(RouteMap.inventory.path) && (
                <Route
                    path={`${path}/${RouteMap.inventory.path}`}
                    exact
                    component={() => (
                        <Inventory
                            allowedSections={inventorySections}
                            model={model}
                        />
                    )}
                />
            )}

            {isAllowedRoute(RouteMap.connmap.path) && (
                <Route
                    path={`${path}/${RouteMap.connmap.path}`}
                    exact
                    component={() => <ConnectionMap />}
                />
            )}

            {isAllowedRoute(RouteMap.microdata.path) && [
                <Route
                    key={RouteMap.microdata.path}
                    path={`${path}/${RouteMap.microdata.path}`}
                    exact
                    component={() => <Microdata modelId={model.id} />}
                />,
                <Route
                    key={'microdata-redirect'}
                    path={`${path}/microdata`}
                    component={() => (
                        <Redirect to={`${url}/microdata/tenants`} />
                    )}
                />,
            ]}

            {isAllowedRoute(RouteMap.modelDesigner.path) && (
                <Route
                    key='labels'
                    exact
                    path={`${path}/labels`}
                    component={() => <LabelsManagement modelId={model.id} />}
                />
            )}

            {isAllowedRoute(RouteMap.modelDesigner.path) && (
                <Route
                    path={`${path}/${RouteMap.modelDesigner.path}`}
                    exact
                    component={() => (
                        <ModelDesigner
                            type='model'
                            info={{
                                name: model.name,
                                id: model.id,
                            }}
                        />
                    )}
                />
            )}

            {!!template && (
                <Route
                    path={`${path}/template-settings`}
                    exact
                    component={() => getTemplate(template)}
                />
            )}

            <Route path='*'>
                {isShared ? (
                    <Redirect to={`${url}/${sharedTabs[0]}`} />
                ) : (
                    <Redirect to={`${url}/kpis`} />
                )}
            </Route>
        </Switch>
    );
};

export default ScenarioRouter;
