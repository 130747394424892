import { getDisabledFinancialStatements } from '@/app-config/featureConfig.ts';
import { FinancialSections } from '@/app-config/types.ts';

import AveragesDataRate from './AveragesDataRate';
import AveragesDataVolume from './AveragesDataVolume';
import AveragesEndPoint from './AveragesEndPoint';
import AveragesTenant from './AveragesTenant';
import CashFlow from './CashFlow';
import OperatingIncome from './OperatingIncome';

type FinancialSection =
    | FinancialSections
    | { section: FinancialSections; title: string };

interface FinancialProps {
    modelId: string;
    allowedSections?: FinancialSection[];
}

const defaultSections: FinancialSection[] = [
    FinancialSections.cashFlow,
    FinancialSections.operatingIncome,
    FinancialSections.averagesEndPoint,
    FinancialSections.averagesTenant,
    FinancialSections.averagesDataRate,
    FinancialSections.averagesDataVolume,
];

const Financial = ({
    modelId,
    allowedSections = defaultSections,
}: FinancialProps) => {
    const disabledStatements = getDisabledFinancialStatements();

    return (
        <div className='mx-auto w-full max-w-screen-5xl'>
            <div className='flex flex-col'>
                {allowedSections.map((sectionItem) => {
                    const section =
                        typeof sectionItem === 'object'
                            ? sectionItem.section
                            : sectionItem;
                    const title =
                        typeof sectionItem === 'object'
                            ? sectionItem.title
                            : undefined;

                    switch (section) {
                        case FinancialSections.cashFlow:
                            return (
                                <CashFlow
                                    key={FinancialSections.cashFlow}
                                    disabledStatements={disabledStatements}
                                    modelId={modelId}
                                />
                            );
                        case FinancialSections.operatingIncome:
                            return (
                                <OperatingIncome
                                    key={FinancialSections.operatingIncome}
                                    disabledStatements={disabledStatements}
                                    modelId={modelId}
                                />
                            );
                        case FinancialSections.averagesEndPoint:
                            return (
                                <AveragesEndPoint
                                    key={FinancialSections.averagesEndPoint}
                                    disabledStatements={disabledStatements}
                                    modelId={modelId}
                                    title={title}
                                />
                            );
                        case FinancialSections.averagesTenant:
                            return (
                                <AveragesTenant
                                    key={FinancialSections.averagesTenant}
                                    disabledStatements={disabledStatements}
                                    modelId={modelId}
                                    title={title}
                                />
                            );
                        case FinancialSections.averagesDataRate:
                            return (
                                <AveragesDataRate
                                    key={FinancialSections.averagesDataRate}
                                    disabledStatements={disabledStatements}
                                    modelId={modelId}
                                />
                            );
                        case FinancialSections.averagesDataVolume:
                            return (
                                <AveragesDataVolume
                                    key={FinancialSections.averagesDataVolume}
                                    disabledStatements={disabledStatements}
                                    modelId={modelId}
                                />
                            );
                        default:
                            return null;
                    }
                })}
            </div>
        </div>
    );
};
export default Financial;
