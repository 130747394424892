import React from 'react';
import { MdDomain } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Tenant, TenantDeployment } from '@bae/data-interface';
import {
    ButtonCancelDialog,
    ButtonSaveDialog,
    InputNumberOutlined,
    InputPercentOutlined,
} from '@bae/ui';

import { Button } from '@/components/ui/button.tsx';
import {
    Dialog,
    DialogContent,
    DialogFooter,
} from '@/components/ui/dialog.tsx';
import { Input } from '@/components/ui/input.tsx';
import { Label } from '@/components/ui/label.tsx';
import { Separator } from '@/components/ui/separator.tsx';
import { Switch } from '@/components/ui/switch.tsx';
import DialogHeader from '@/libs/ui/src/lib/DialogHeader.tsx';

import { getEndpointType, getId } from '../store/selectors';
import TenantConfigAdvanced from './TenantConfigAdvanced.tsx';
import { useAddTenant } from './api/add-tenant.ts';
import { useEditTenant } from './api/edit-tenant.ts';

interface TenantConfigProps {
    open: boolean;
    tenant: Tenant;
    mode: 'add' | 'edit';

    onClose: () => void;
}

const TenantConfig = ({ open, mode, onClose, tenant }: TenantConfigProps) => {
    const type = useSelector(getEndpointType);
    const id = useSelector(getId);

    const addTenantQuery = useAddTenant({
        mutationConfig: {
            onSuccess: () => {
                toast.success('Tenant created successfully', {
                    autoClose: 2000,
                });
                onClose();
            },
            onError: (error) => {
                toast.error(String(error) || 'Failed to create Tenant');
                onClose();
            },
        },
    });

    const editTenantQuery = useEditTenant({
        mutationConfig: {
            onSuccess: () => {
                toast.success('Tenant updated successfully', {
                    autoClose: 2000,
                });
                onClose();
            },
            onError: (error) => {
                toast.error(String(error) || 'Failed to update Tenant');
                onClose();
            },
        },
    });

    const [growthMode, setGrowthMode] = React.useState<'cagr' | 'terminal'>(
        'cagr',
    );
    const [currentData, setCurrentData] = React.useState<Tenant>({
        name: '',
        id: undefined,
        deployment: [],
    });
    const [singleDeployment, setSingleDeployment] =
        React.useState<TenantDeployment>({
            name: '',
            quantityInitial: 1,
            quantityTerminal: null,
            quantityGrowth: 0,
            delay: 0,
        });
    const [showAdvanced, setShowAdvanced] = React.useState<boolean>(false);
    const [multipleDelays, setMultipleDelays] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (tenant && mode === 'edit') {
            setCurrentData(tenant);

            const deploymentZero = tenant.deployment.find((d) => d.delay === 0);

            if (deploymentZero) {
                setSingleDeployment(deploymentZero);
                setGrowthMode(
                    deploymentZero.quantityGrowth !== null
                        ? 'cagr'
                        : 'terminal',
                );
            } else {
                setSingleDeployment({
                    name: tenant.name,
                    quantityInitial: 1,
                    quantityTerminal: null,
                    quantityGrowth: 0,
                    delay: 0,
                });
            }

            const multipleDelays =
                tenant.deployment.length > 1 || !deploymentZero;
            setShowAdvanced(multipleDelays);
            setMultipleDelays(multipleDelays);
        } else {
            setCurrentData({ name: '', id: undefined, deployment: [] });
            setSingleDeployment({
                name: '',
                quantityInitial: 1,
                quantityTerminal: null,
                quantityGrowth: 0,
                delay: 0,
            });
            setGrowthMode('cagr');
            setShowAdvanced(false);
            setMultipleDelays(false);
        }
    }, [tenant, mode]);

    // Add this useEffect to update multipleDelays when deployments change
    React.useEffect(() => {
        const deploymentZero = currentData.deployment.find(
            (d) => d.delay === 0,
        );
        const hasMultipleDelays =
            currentData.deployment.length > 1 || !deploymentZero;
        setMultipleDelays(hasMultipleDelays);
    }, [currentData.deployment]);

    const handleChangeName = (value: string) => {
        setCurrentData({
            ...currentData,
            name: value,
        });
        setSingleDeployment({
            ...singleDeployment,
            name: value,
        });
    };

    const handleSwitchGrowthMode = (checked: boolean) => {
        setGrowthMode(checked ? 'cagr' : 'terminal');
        setSingleDeployment((prev) => ({
            ...prev,
            quantityGrowth: checked ? prev.quantityGrowth ?? 0 : null,
            quantityTerminal: checked
                ? null
                : prev.quantityTerminal ?? prev.quantityInitial,
        }));
    };

    const handleChangeDeployment = (value: number, name: string) => {
        setSingleDeployment({
            ...singleDeployment,
            [name]: value,
        });
    };

    const handleClickSave = () => {
        const zeroDeploy = currentData.deployment.find((d) => d.delay === 0);

        const deployment = showAdvanced
            ? currentData.deployment
            : [
                  {
                      ...singleDeployment,
                      name: currentData.name,
                      delay: 0,
                  },
              ];

        const timeSeriesPayload =
            !showAdvanced && zeroDeploy
                ? {
                      modelId: id,
                      type,
                      prevData: zeroDeploy,
                      nextData: {
                          name: currentData.name,
                          quantityInitial: singleDeployment.quantityInitial,
                          quantityTerminal: singleDeployment.quantityTerminal,
                          quantityGrowth: singleDeployment.quantityGrowth,
                          delay: 0,
                      },
                  }
                : null;

        if (mode === 'edit') {
            const editPayload = {
                modelId: id,
                type,
                prevData: tenant,
                nextData: {
                    ...currentData,
                    deployment,
                },
                deployment: timeSeriesPayload,
            };

            editTenantQuery.mutate(editPayload);
            return;
        }

        if (mode === 'add') {
            const addPayload = {
                modelId: id,
                type,
                data: {
                    ...currentData,
                    deployment,
                },
            };

            addTenantQuery.mutate(addPayload);
            return;
        }
    };

    const disableSave = currentData.name.trim() === '';

    return (
        <div id='dialog-md-config'>
            <Dialog open={open} onOpenChange={onClose}>
                <DialogContent
                    className={`max-h-[100vh] overflow-y-auto ${showAdvanced ? 'max-w-7xl' : ''}`}>
                    <DialogHeader
                        title={
                            mode === 'edit' ? currentData.name : 'New Tenant'
                        }
                        icon={
                            <MdDomain className='size-6 text-newDesign-primary' />
                        }
                    />
                    <Separator />
                    <div className='flex'>
                        <div
                            className={`flex h-full flex-wrap items-center justify-between ${
                                showAdvanced ? 'w-1/2' : 'w-full'
                            }`}>
                            <div className='relative mb-4 w-full'>
                                <Label
                                    htmlFor='tenant-name'
                                    className='mb-1.5 ml-0.5 flex'>
                                    Tenant Name*
                                </Label>
                                <Input
                                    id='tenant-name'
                                    required
                                    value={currentData.name}
                                    name='name'
                                    onChange={(e) =>
                                        handleChangeName(e.target.value)
                                    }
                                    className={
                                        currentData.name.trim() === ''
                                            ? 'border-newDesign-error'
                                            : ''
                                    }
                                />
                                <div className='flex h-6 items-center'>
                                    {currentData.name.trim() === '' && (
                                        <p className='ml-0.5 mt-1 text-newDesign-error'>
                                            A name is required!
                                        </p>
                                    )}
                                </div>
                            </div>

                            {!showAdvanced && (
                                <>
                                    <div className='mb-4 flex w-full flex-wrap items-center justify-between px-0.5'>
                                        <p>Quantity</p>
                                        <div className='flex items-center gap-2'>
                                            <Label>Terminal</Label>
                                            <Switch
                                                checked={growthMode === 'cagr'}
                                                onCheckedChange={
                                                    handleSwitchGrowthMode
                                                }
                                            />
                                            <Label>CAGR</Label>
                                        </div>
                                    </div>
                                    <div className='mb-4 flex w-full items-center gap-4'>
                                        <InputNumberOutlined
                                            label='Initial'
                                            value={
                                                singleDeployment.quantityInitial
                                            }
                                            name='quantityInitial'
                                            onChange={handleChangeDeployment}
                                            min={0}
                                        />

                                        {growthMode === 'terminal' && (
                                            <InputNumberOutlined
                                                label='Terminal'
                                                value={
                                                    singleDeployment.quantityTerminal
                                                }
                                                name='quantityTerminal'
                                                step={1}
                                                onChange={
                                                    handleChangeDeployment
                                                }
                                                min={0}
                                            />
                                        )}

                                        {growthMode === 'cagr' && (
                                            <div className='w-full'>
                                                <Label className='ml-1'>
                                                    CAGR
                                                </Label>
                                                <InputPercentOutlined
                                                    label='CAGR'
                                                    name='quantityGrowth'
                                                    step={1}
                                                    onChange={
                                                        handleChangeDeployment
                                                    }
                                                    value={
                                                        singleDeployment.quantityGrowth
                                                    }
                                                    className='w-full'
                                                    min={0}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}

                            <div className='mb-2 flex w-full justify-end'>
                                {showAdvanced && !multipleDelays && (
                                    <Button
                                        id='show-default'
                                        variant='outline'
                                        onClick={() => setShowAdvanced(false)}>
                                        {'<< Default'}
                                    </Button>
                                )}

                                {!showAdvanced && (
                                    <Button
                                        id='show-advanced'
                                        variant='outline'
                                        onClick={() => setShowAdvanced(true)}>
                                        {'Advanced >>'}
                                    </Button>
                                )}
                            </div>
                        </div>

                        {showAdvanced && (
                            <div className='w-1/2 pl-4'>
                                <TenantConfigAdvanced data={currentData} />
                            </div>
                        )}
                    </div>
                    <Separator />
                    <DialogFooter>
                        <ButtonCancelDialog onClick={onClose} />

                        <ButtonSaveDialog
                            disabled={disableSave}
                            onClick={() => handleClickSave()}
                            type={mode === 'add' ? 'add' : 'save'}
                        />
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default TenantConfig;
