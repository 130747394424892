import { MutationConfig } from '@/libs/api/src/lib/react-query.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AxiosAPIv2, getToken } from '@bae/api';

import { listModelGroupsQueryOptions } from '@/features/model-groups/api/list-model-groups.ts';

export type APIModelGroupPatch = Array<{
    group_id: string;
    group_name: string;
}>;

const patchModelGroup = (payload: APIModelGroupPatch): Promise<void> =>
    AxiosAPIv2.axiosInstance.patch('model_groups', payload, {
        headers: { Authorization: 'Bearer ' + getToken() },
    });

type UsePatchModelGroupOptions = {
    mutationConfig?: MutationConfig<typeof patchModelGroup>;
};

export const usePatchModelGroup = ({
    mutationConfig,
}: UsePatchModelGroupOptions) => {
    const queryClient = useQueryClient();

    const { onSuccess, ...restConfig } = mutationConfig || {};

    return useMutation({
        onSuccess: async (...args) => {
            await queryClient.invalidateQueries({
                queryKey: listModelGroupsQueryOptions().queryKey,
            });

            onSuccess?.(...args);
        },
        ...restConfig,
        mutationFn: patchModelGroup,
    });
};
