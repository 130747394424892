import React from 'react';
import { TbArrowsDiff } from 'react-icons/tb';

import { Button } from '@/components/ui/button.tsx';
import CompareDialog from '@/features/scenario/components/CompareDialog.tsx';

const CompareDialogTrigger = ({ initialSelected }) => {
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <Button variant='outline' withIcon onClick={() => setOpen(true)}>
                <TbArrowsDiff className='mr-2 size-6' />
                Compare
            </Button>
            <CompareDialog
                initialSelected={initialSelected}
                isModalOpen={open}
                closeModal={() => setOpen(false)}
            />
        </>
    );
};

export default CompareDialogTrigger;
