import { QueryConfig } from '@/libs/api/src/lib/react-query.ts';
import { queryOptions, useQuery } from '@tanstack/react-query';

import { AxiosAPIv2, getToken } from '@bae/api';

import type { Device, DeviceType } from '../types.ts';

interface GetMistDevicesParams {
    deviceType: DeviceType;
}

export const getMistDevices = async ({
    deviceType,
}: GetMistDevicesParams): Promise<Device[]> => {
    const response = await AxiosAPIv2.axiosInstance.get<Device[]>(
        '/partners/mist/devices',
        {
            headers: { Authorization: 'Bearer ' + getToken() },
            params: {
                device_type: deviceType,
            },
        },
    );

    return response.data;
};

export const getMistDevicesQueryOptions = ({
    deviceType,
}: GetMistDevicesParams) => {
    return queryOptions({
        queryKey: ['mist', 'devices', deviceType],
        queryFn: () => getMistDevices({ deviceType }),
    });
};

type UseGetMistDevicesOptions = {
    deviceType: DeviceType;
    queryConfig?: QueryConfig<typeof getMistDevicesQueryOptions>;
    enabled?: boolean;
};

export const useGetMistDevices = ({
    queryConfig,
    deviceType,
    enabled = true,
}: UseGetMistDevicesOptions) => {
    return useQuery({
        ...getMistDevicesQueryOptions({ deviceType }),
        ...queryConfig,
        enabled,
    });
};
