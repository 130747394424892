import { ColumnDef, Table as TableDef } from '@tanstack/react-table';
import { CSSProperties } from 'react';

export const getShowingResultsPaginationText = <T>(table: TableDef<T>) => {
    const { pageIndex, pageSize } = table.getState().pagination;
    const totalItems = table.getFilteredRowModel().rows.length;

    if (totalItems === 0) {
        return null;
    }

    const firstItemIndex = pageIndex * pageSize + 1;
    const lastItemIndex = Math.min(totalItems, (pageIndex + 1) * pageSize);

    return `Showing ${firstItemIndex} - ${lastItemIndex} of ${totalItems} results`;
};

export const getStickyStyles = <TData>(
    column: ColumnDef<TData>,
): CSSProperties => {
    const isSticky = column.meta?.sticky;
    return {
        opacity: 0.9,
        position: isSticky ? 'sticky' : 'relative',
        right: isSticky === 'right' ? '0' : undefined,
        backgroundColor: 'white',
        zIndex: 1,
        boxShadow:
            isSticky === 'right'
                ? 'inset 4px 0 4px -4px rgba(0, 0, 0, 0.1)'
                : undefined,
    };
};
