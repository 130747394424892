import { cva, type VariantProps } from 'class-variance-authority';

import * as React from 'react';

import { cn } from '@/lib/utils';

const badgeVariants = cva(
    'inline-flex items-center rounded-full border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
    {
        variants: {
            variant: {
                'default':
                    'border-transparent bg-newDesign-primary text-white hover:bg-newDesign-primary/80',
                'secondary':
                    'border-transparent bg-newDesign-secondary text-white hover:bg-newDesign-secondary/80',
                'destructive':
                    'border-transparent bg-newDesign-alert text-white hover:bg-newDesign-alert-light',
                'outline': 'text-newDesign-primary border-newDesign-primary',
                'warning':
                    'border-transparent bg-newDesign-warning-dark text-white hover:bg-newDesign-warning-light',
                'warning-outline':
                    'border-newDesign-warning-dark bg-transparent text-newDesign-warning-dark hover:bg-newDesign-divider',
            },
        },
        defaultVariants: {
            variant: 'default',
        },
    },
);

export interface BadgeProps
    extends React.HTMLAttributes<HTMLDivElement>,
        VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
    return (
        <div className={cn(badgeVariants({ variant }), className)} {...props} />
    );
}

export { Badge, badgeVariants };
