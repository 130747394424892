import { z } from 'zod';

import { QueryConfig } from '@/libs/api/src/lib/react-query.ts';
import { queryOptions, useQuery } from '@tanstack/react-query';

import { formatDate } from '@bae/utils';

const getDigitalTwinSchema = z.object({
    id: z.string().min(1, 'Required'),
    name: z.string().min(1, 'Required'),
    createdAt: z.string().min(1, 'Required'),
});

export type IDigitalTwinData = z.infer<typeof getDigitalTwinSchema>;

const getDigitalTwin = async (): Promise<IDigitalTwinData> => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({
                id: 'b9f8f2e2-b62f-4263-9034-40a221b01616', // unstable
                // id: '13fbfc6f-6872-4a9b-a33d-bdb5aae37f23', // bae-mist stable
                name: 'Digital Twin',
                createdAt: formatDate(Date.now()),
            });
        }, 500);
    });
};

export const getScenariosQueryOptions = () => {
    return queryOptions({
        queryKey: ['digital-twin'],
        queryFn: () => getDigitalTwin(),
    });
};

type UseDigitalTwinOptions = {
    queryConfig?: QueryConfig<typeof getDigitalTwin>;
    refetchInterval?: number | false;
};

export const useDigitalTwin = ({
    queryConfig,
    refetchInterval,
}: UseDigitalTwinOptions) => {
    return useQuery({
        ...getScenariosQueryOptions(),
        ...queryConfig,
        refetchInterval,
    });
};
