import React, { ComponentProps } from 'react';

import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from '@/components/ui/tooltip.tsx';
import { cn } from '@/lib/utils.ts';

const TooltipWrapper = ({
    children,
    pointerEvents = false,
    align = 'center',
    side = 'top',
    bgColor = 'bg-newDesign-tooltip',
    sideOffset = 8,
    alignOffset = 0,
    text,
    className,
}: {
    pointerEvents?: boolean;
    align?: 'start' | 'center' | 'end';
    side?: 'left' | 'top' | 'right' | 'bottom';
    bgColor?: string;
    sideOffset?: number;
    alignOffset?: number;
    children: React.ReactNode;
    text: string | null;
    className?: ComponentProps<'div'>['className'];
}) => {
    if (!text) return children;

    return (
        <TooltipProvider delayDuration={100}>
            <Tooltip>
                <TooltipTrigger asChild>{children}</TooltipTrigger>
                <TooltipContent
                    side={side}
                    align={align}
                    sideOffset={sideOffset}
                    alignOffset={alignOffset}
                    className={cn(
                        `${pointerEvents ? '' : 'pointer-events-none select-none'} z-[9999] max-w-[350px] ${bgColor}`,
                        className,
                    )}>
                    <p className='whitespace-break-spaces break-words text-white'>
                        {text}
                    </p>
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    );
};

export default TooltipWrapper;
