import { QueryConfig } from '@/libs/api/src/lib/react-query.ts';
import { queryOptions, useQuery } from '@tanstack/react-query';

import { APIModelGroupCollection, AxiosAPIv2, getToken } from '@bae/api';
import { ModelGroup } from '@bae/data-interface';

const adapter = (data: APIModelGroupCollection): ModelGroup[] => {
    return data.model_groups.map((group) => ({
        id: group.group_id,
        name: group.group_name,
        type: group.group_type,
        models: group.models.map((model) => ({
            id: model.model_id,
            groupId: group.group_id,
            name: model.model_name,
            description: model.model_description,
            createdAt: model.created_at,
            createdBy: model.created_by,
            lastUpdateAt: model.lastupdate_at,
            lastUpdateBy: model.lastupdate_by,
        })),
        periods: group.periods,
    }));
};

export const listModelGroups = async (): Promise<ModelGroup[]> => {
    const response =
        await AxiosAPIv2.axiosInstance.get<APIModelGroupCollection>(
            `model_groups`,
            { headers: { Authorization: 'Bearer ' + getToken() } },
        );

    return adapter(response.data);
};

export const listModelGroupsQueryOptions = () => {
    return queryOptions({
        queryKey: ['model_groups'],
        queryFn: () => listModelGroups(),
    });
};

type UseListModelGroupsOptions = {
    queryConfig?: QueryConfig<typeof listModelGroupsQueryOptions>;
    enabled?: boolean;
};

export const useListModelGroups = ({
    queryConfig,
    enabled = true,
}: UseListModelGroupsOptions) => {
    return useQuery({
        ...listModelGroupsQueryOptions(),
        ...queryConfig,
        enabled,
    });
};
