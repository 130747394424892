import React, { useState, FC, useEffect } from 'react';
import { LuPlus } from 'react-icons/lu';

import { Button } from '@/components/ui/button.tsx';
import { Dialog, DialogTrigger } from '@/components/ui/dialog.tsx';

import AccessPointDialog from '../AccessPoints/components/AccessPointDialog.tsx';
import FteDialog from '../FTE/components/FteDialog.tsx';
import SiteDialog from '../Sites/components/SiteDialog.tsx';

interface Props {
    selectedTab:
        | 'accessPoints'
        | 'fte'
        | 'sites'
        | 'licenses'
        | 'configuration';
}

const AddNewDialogContainer: FC<Props> = ({ selectedTab }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    useEffect(() => {
        setIsOpen(false);
    }, [selectedTab]);

    return (
        <Dialog onOpenChange={setIsOpen} open={isOpen}>
            <DialogTrigger asChild>
                <Button size='sm' withIcon variant='outline'>
                    <LuPlus className='mr-1 size-4' />
                    Add
                </Button>
            </DialogTrigger>
            {selectedTab === 'accessPoints' && (
                <AccessPointDialog
                    isOpen={isOpen}
                    closeModal={() => setIsOpen(false)}
                />
            )}

            {selectedTab === 'fte' && (
                <FteDialog
                    isOpen={isOpen}
                    closeModal={() => setIsOpen(false)}
                />
            )}

            {selectedTab === 'sites' && (
                <SiteDialog
                    isOpen={isOpen}
                    closeModal={() => setIsOpen(false)}
                />
            )}
        </Dialog>
    );
};

export default AddNewDialogContainer;
