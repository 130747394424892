import { AxiosResponse } from 'axios';

import { MutationConfig } from '@/libs/api/src/lib/react-query.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AxiosAPIv2, getToken } from '@bae/api';

import { mistDataInputAdapter } from '@/app/shared-pages/Scenario/components/digital-twin/MistSettings/api/adapters.tsx';
import { MistDataInput } from '@/app/shared-pages/Scenario/components/digital-twin/MistSettings/types.ts';

interface PostMistModelResponse {
    group_id: string;
    model_id: string;
    group_name: string;
}

interface PostMistModelParams {
    group_name?: string;
    mist_input?: MistDataInput;
    replace_group_id?: string;
}

const postMistModel = async ({
    group_name,
    mist_input,
    replace_group_id,
}: PostMistModelParams): Promise<PostMistModelResponse> => {
    const payload = {};

    if (group_name) {
        payload['group_name'] = group_name;
    }

    if (mist_input) {
        payload['mist_input'] = mistDataInputAdapter(mist_input);
    }

    if (replace_group_id) {
        payload['replace_group_id'] = replace_group_id;
    }

    const response: AxiosResponse<PostMistModelResponse> =
        await AxiosAPIv2.axiosInstance.post('/partners/mist/model', payload, {
            headers: { Authorization: 'Bearer ' + getToken() },
        });

    return response.data;
};

type UsePostMistModelOptions = {
    mutationConfig?: MutationConfig<typeof postMistModel>;
};

export const usePostMistModel = ({
    mutationConfig,
}: UsePostMistModelOptions) => {
    const queryClient = useQueryClient();

    const { onSuccess, ...restConfig } = mutationConfig || {};

    return useMutation({
        onSuccess: async (...args) => {
            await queryClient.invalidateQueries({
                queryKey: ['model_groups'],
            });

            onSuccess?.(...args);
        },
        ...restConfig,
        mutationFn: postMistModel,
    });
};
