import React from 'react';

import { cn } from '@/lib/utils.ts';

const StatusBadge = ({
    status,
}: {
    status: 'original' | 'modified' | 'added';
}) => {
    return (
        <span
            className={cn('size-3 rounded-[3px]', {
                'bg-newDesign-info': status === 'original',
                'bg-newDesign-warning': status === 'modified',
                'bg-newDesign-success': status === 'added',
            })}></span>
    );
};

export default StatusBadge;
