import { MutationConfig } from '@/libs/api/src/lib/react-query.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AxiosAPIv2, getToken } from '@bae/api';

import { listModelGroupsQueryOptions } from '@/features/model-groups/api/list-model-groups.ts';

const deleteModelGroup = ({
    groupIds,
}: {
    groupIds: string[];
}): Promise<void> =>
    AxiosAPIv2.axiosInstance.delete('model_groups', {
        headers: { Authorization: 'Bearer ' + getToken() },
        data: groupIds,
    });

type UseDeleteModelGroupOptions = {
    mutationConfig?: MutationConfig<typeof deleteModelGroup>;
};

export const useDeleteModelGroups = ({
    mutationConfig,
}: UseDeleteModelGroupOptions) => {
    const queryClient = useQueryClient();

    const { onSuccess, ...restConfig } = mutationConfig || {};

    return useMutation({
        onSuccess: async (...args) => {
            await queryClient.invalidateQueries({
                queryKey: listModelGroupsQueryOptions().queryKey,
            });

            onSuccess?.(...args);
        },
        ...restConfig,
        mutationFn: deleteModelGroup,
    });
};
