import React, { useState } from 'react';
import { MdDomain } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { TenantDeployment } from '@bae/data-interface';
import { ButtonCancelDialog, ButtonSaveDialog, DialogHeader } from '@bae/ui';

import {
    Dialog,
    DialogContent,
    DialogFooter,
} from '@/components/ui/dialog.tsx';
import { Input } from '@/components/ui/input.tsx';
import { Label } from '@/components/ui/label.tsx';
import { Separator } from '@/components/ui/separator.tsx';

import { getEndpointType, getId } from '../store/selectors';
import { useAddTenant } from './api/add-tenant.ts';

interface TenantConfigProps {
    open: boolean;
    onClose: () => void;
    setConfigState: React.Dispatch<
        React.SetStateAction<{
            open: boolean;
            selectedName: string;
            mode: 'edit' | 'add';
        }>
    >;
}

const getDefaultDeployment = (name: string): TenantDeployment[] => [
    {
        name,
        quantityInitial: 1,
        quantityTerminal: 1,
        quantityGrowth: null,
        delay: 0,
    },
];

const NewTenantDialog = ({
    open,
    onClose,
    setConfigState,
}: TenantConfigProps) => {
    const type = useSelector(getEndpointType);
    const id = useSelector(getId);

    const [name, setName] = useState('');

    const addTenantQuery = useAddTenant({
        mutationConfig: {
            onSuccess: (data) => {
                toast.success('Tenant created successfully', {
                    autoClose: 2000,
                });

                setTimeout(() => {
                    setConfigState({
                        open: true,
                        selectedName: data.name,
                        mode: 'edit',
                    });
                }, 500);

                onClose();
            },
            onError: (error) => {
                toast.error(String(error) || 'Failed to create Tenant');
                onClose();
            },
        },
    });

    const handleAddTenant = () => {
        const addPayload = {
            modelId: id,
            type,
            data: {
                name,
                deployment: getDefaultDeployment(name),
            },
        };

        addTenantQuery.mutate(addPayload);
    };

    return (
        <div id='dialog-md-config'>
            <Dialog open={open} onOpenChange={onClose}>
                <DialogContent className={`max-h-[100vh] overflow-y-auto`}>
                    <DialogHeader
                        title='New Tenant'
                        icon={
                            <MdDomain className='size-6 text-newDesign-primary' />
                        }
                    />
                    <Separator />
                    <div className='flex'>
                        <div
                            className={`flex h-full w-full flex-wrap items-center justify-between`}>
                            <div className='relative mb-4 w-full'>
                                <Label
                                    htmlFor='tenant-name'
                                    className='mb-1.5 ml-0.5 flex'>
                                    Tenant Name*
                                </Label>
                                <Input
                                    id='tenant-name'
                                    required
                                    value={name}
                                    name='name'
                                    onChange={(e) => setName(e.target.value)}
                                    className={
                                        name.trim() === ''
                                            ? 'border-newDesign-error'
                                            : ''
                                    }
                                />
                                <div className='flex h-6 items-center'>
                                    {name.trim() === '' && (
                                        <p className='ml-0.5 mt-1 text-newDesign-error'>
                                            A name is required!
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <Separator />
                    <DialogFooter>
                        <ButtonCancelDialog onClick={onClose} />

                        <ButtonSaveDialog
                            disabled={addTenantQuery.isPending}
                            onClick={handleAddTenant}
                            type='add'
                        />
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default NewTenantDialog;
