import {
    flexRender,
    getCoreRowModel,
    getPaginationRowModel,
    useReactTable,
} from '@tanstack/react-table';
import React from 'react';

import { NoData } from '@bae/ui';

import { getStickyStyles } from '@/app/shared-pages/Scenario/components/digital-twin/MistSettings/components/utils.ts';
import useMistStore from '@/app/shared-pages/Scenario/components/digital-twin/MistSettings/store';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from '@/components/ui/table';

import type { AccessPointInput } from '../../types.ts';
import TablePagination from '../shared/TablePagination.tsx';
import { columnConfig } from './columnConfig.tsx';

const AccessPointsTable = () => {
    const accessPointsData = useMistStore((state) => state.data.accessPoints);

    const table = useReactTable({
        data: accessPointsData,
        columns: columnConfig,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        rowCount: accessPointsData.length,
        initialState: { pagination: { pageSize: 5 } },
        getRowId: (row) => `${row?.name}@${row?.site}`,
    });

    const noData = table.getRowModel().rows.length === 0;

    return (
        <section className='flex w-full flex-col'>
            <Table>
                <TableHeader>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                return (
                                    <TableHead
                                        style={{
                                            ...getStickyStyles(
                                                header.column.columnDef,
                                            ),
                                            minWidth: header.column.getSize(),
                                        }}
                                        key={header.id}>
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(
                                                  header.column.columnDef
                                                      .header,
                                                  header.getContext(),
                                              )}
                                    </TableHead>
                                );
                            })}
                        </TableRow>
                    ))}
                </TableHeader>
                <TableBody className='w-full'>
                    {table.getRowModel().rows.map((row, index) => (
                        <TableRow
                            index={index}
                            data-state={row.getIsSelected() && 'selected'}
                            key={row.id}>
                            {row.getVisibleCells().map((cell) => (
                                <TableCell
                                    style={{
                                        ...getStickyStyles(
                                            cell.column.columnDef,
                                        ),
                                        minWidth: cell.column.getSize(),
                                    }}
                                    key={cell.id}>
                                    {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext(),
                                    )}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}

                    {noData && (
                        <TableRow>
                            <TableCell colSpan={13}>
                                <div className='py-10'>
                                    <NoData p='p-0' text='No Access Points' />
                                </div>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>

            <TablePagination<AccessPointInput> table={table} />
        </section>
    );
};

export default AccessPointsTable;
